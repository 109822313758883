var media = document.getElementById("myVideo1");
var media2 = document.getElementById("myVideo2");
var btn = document.getElementById("myBtn1");
var btn2 = document.getElementById("myBtn2");
var text = document.getElementById("video-text");
var text2 = document.getElementById("video-text2");
media.addEventListener('click', () => {
    media.pause()
    btn.style.display = "inline-block";
    text.style.display = "block";
})
media2.addEventListener('click', () => {
    media2.pause()
    btn2.style.display = "inline-block";
    text2.style.display = "block";
})
btn.addEventListener('click', () => {
    media.play()
    btn.style.display = "none";
    text.style.display = "none";
})
btn2.addEventListener('click', () => {
    media2.play()
    btn2.style.display = "none";
    text2.style.display = "none";
})